/* ================================================================
   Variables
   ================================================================ */

// Color Variables
$color-blue:#1b75d5;
$color-grey: #5b5b5b;
$color-grey2: #838383;
$color-grey3: #656565;
$color-black: #000;
$color-white:#fff;

$body-bg: #fff;
$color-base: $color-grey;
$color-invert: #fff;
$color-primary: $color-blue;

$colors: (
    "primary": $color-primary,
    "blue": $color-blue,
    "grey": $color-grey,
    "grey2": $color-grey2,
    "grey3": $color-grey3,
    "black": $color-black,
    "white": $color-white
);


// Typography
$font-primary: 'Open Sans', sans-serif;
$font-secondary: 'Open Sans', sans-serif;
$font-heading: $font-secondary;

$fontWeight-light: 300;
$fontWeight-normal: 400;
$fontWeight-medium: 500;
$fontWeight-semibold: 600;
$fontWeight-bold: 700;

$typography-margin-bottom: 1.5em;


// Breakpoints
// You can convert these at http://pxtoem.com
$bp-s: 0; // 320px
$bp-s-max: 29.938em; // 479px
$bp-sw: 30em; // 480px
$bp-sw-max: 47.938em; // 767px
$bp-m: 48em; // 768px
$bp-m-max: 63.9375em; // 959px
$bp-mw: 64em; // 1024px
$bp-mw-max: 71.9375em; // 1139px
$bp-l: 80em; // 1152px
$bp-l-max: 85.3125rem; // 1365px
$bp-xl: 85.375em; // 1366px
$bp-xxl: 90rem; // 1440px

$breakpoints: (
    xs: 0, 
    sm: $bp-s, 
    md: $bp-m,   
    lg: $bp-l, 
    xl: $bp-xl,
    xxxl: $bp-xxl
);

$grid-container-width:87.5em;

// Navigation Variables
$nav-transition-speed: 0.4s;
$nav-barBackground: #333;
$nav-mainLinkBackground: #666;
$nav-subLinkBackground: #999;


// Grid
$grid-gutter-em: 0.625rem; // Changing grid gutter to em causes a compiling error :/

// Typography
$base-font-size:16px;