@use '../../abstracts/' as *;

.icon-card {
    display: flex;
    gap: 40px;
    padding:25px 15px 80px 30px;
    border-right:5px solid transparent;
    background:transparent;
    transition:background .3s ease-in-out, border-right-color .3s ease-in-out;

    &:hover,
    &:focus-within {
        border-right-color:$color-blue;
        background:$color-white;
    }

    .icon-card__media {
        flex-shrink: 0;
    }

    .icon-card__body {
        margin:0 auto;
    }
}

.icon-card__body {
    text-align: center;

    .button {
        font-size:0.8125rem;
        line-height: 1.5;
    }
}

.icon-card__title {
    margin-bottom:20px;
    font-size:1.5rem;
    font-weight:$fontWeight-normal;
    line-height: 24px;
}