@use '../../abstracts/' as *;

.hero-slider {
    display: flex;
    overflow: hidden;

    & > .tns-item {
    }
}

.hero-slider-ctrls {
    position:absolute;
    bottom:0;
    right:0;
    z-index:9;
    display: flex;
    align-items: center;
    gap:15px;
    padding-bottom:15px;
    padding-inline:15px;
}

.hero-slider-ctrl {
    @extend %buttonreset;
    position: relative;
    color:$color-white;

    &.hero-slider-ctrl--prev:before,
    &.hero-slider-ctrl--next:before {
        @include pseudo(block, static, '');
        border:9px solid transparent;
    }

    &.hero-slider-ctrl--prev:before {
        border-right-color:currentColor;
    }

    &.hero-slider-ctrl--next:before {
        border-left-color: currentColor;
    }
}

@media(min-width:$bp-m){
    .hero-slider-ctrls {
        width:100%;
        max-width:46%;
        justify-content: flex-start;
    }

    [class*="col-md-"] {
        .hero-slider-wrap,
        .tns-outer,
        .tns-inner,
        .hero-slider {
            position:relative;
            height:100%;
        }

        .hero  {
            height:100%;
            min-height:490px;
        }
    }
}

@media(min-width:$bp-mw){
    .hero-slider-ctrls {
        max-width:373px;
    }
}