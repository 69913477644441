@use '../abstracts' as *;

.site-search__toggle {
    @extend %buttonreset;
    
    .icon {
        width: 24px;
        height: 24px;
    }
}

.site-search__controls {
    display: none;
    position:absolute;
    top:100%;
    left:0;
    z-index: 12;
    width:100%;

    .site-search__submit {
        position:absolute;
        top:0;
        left:0;
        width:48px;
        height:100%;

        .icon {
            width:45%;
            height:45%;
        }
    }
}

input[type="text"].site-search__input {
    width:100%;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding:5px 5px 5px 48px;
}

.site-search__submit {
    @extend %buttonreset;

    .icon {
        width:48px;
        height:36px;
    }
}


.site-search__suggestions {
    @extend %listreset;
    position:absolute;
    top:100%;
    left:0;
    z-index:100;
    width:100%;
    border-top:2px solid $color-primary;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.1);
    background:$color-white;

    .dropdown-item {
        @extend %buttonreset;
        width:100%;
        padding:10px;
        text-align: left;
        transition:background .3s ease-in-out, color .3s ease-in-out;

        &:hover,
        &:focus-visible {
            background:$color-primary;
            color:$color-white;
        }
    }
}

@media(min-width:$bp-mw){
    .site-search__toggle {
        display: none;
    }

    .site-search__controls {
        position:relative;
        top:auto;
        left:auto;
        display: block !important;
    }

    input[type="text"].site-search__input {
        width:310px;
    }
}