@use '../abstracts' as *;

:root {
    @each $color, $colorVal in $colors {
        --color-#{$color}:#{$colorVal};
    }
    --font-size-h1:2.3125rem;
    --font-size-h2:clamp(1.875rem, 2.5vw, 35px);
    --font-size-h3:clamp(1.625rem, 2.2vw, 1.9375rem);
    --font-size-h4:1.25rem;
    --font-size-h5:.83rem;
    --font-size-h6:.67rem;
    --line-height-heading:inherit;
    --color-h1:var(--color-blue);
    --color-h2:var(--color-blue);
    --color-h3:var(--color-blue);
    --color-h4:var(--color-blue);
    --color-h5:var(--color-blue);
    --color-h6:var(--color-blue);
    --color-heading:var(--color-blue);
    --link-color:var(--color-blue);
    --sf-backgrоund-color:transparent;
    --sf-backgrоund-image:none;
    --sf-background-size:auto;
    --sf-background-position:0 0;
}

html,
button,
input,
select,
textarea {
    color: $color-base;
}

html {
    border-top:6px solid $color-primary;
    background:#fafafa;
}

body {
    max-width:68.9375em;
    margin:0 auto;
    font-family: $font-primary;
    line-height: 1.4;
    overflow-y: scroll;
    background: $body-bg;
    box-shadow: -6px 0 12px -4px #d0d0d0;
}

::-moz-selection {
    background: $color-primary;
    color: $color-invert;
    text-shadow: none;
}

::selection {
    background: $color-primary;
    color: $color-invert;
    text-shadow: none;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    margin: 1em 0;
    padding: 0;
    border-top: 1px solid #ccc;
    color: #e5e5e5;
    background-color: #e5e5e5;
}

img {
    vertical-align: middle;
    width: auto;
    height: auto;
    max-width: 100%;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

.hidden {
    display: none !important;
    visibility: hidden;
}

.sr-only {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    margin: -1px;
    padding: 0;
    border: 0;
    width: 1px;
    height: 1px;
}

.sr-only.focusable:active,
.sr-only.focusable:focus {
    position: static;
    overflow: visible;
    clip: auto;
    margin: 0;
    width: auto;
    height: auto;
}

.invisible {
    visibility: hidden;
}

figure {
    margin: 1em 0;
}

iframe {
    border: 0;
    max-width:100%;
}

/* ================================================================
   Talon Util Boilerplate Additions
================================================================ */

*:focus:not(:focus-visible) {
    outline:0;
}

svg.icon {
    fill:currentColor;
}

.sf-Code {
    pre {
        display: block;
        padding: 9.5px;
        margin: 0 0 10px;
        font-size: 13px;
        line-height: 1.42857143;
        color: #333;
        word-break: break-all;
        word-wrap: break-word;
        background-color: #f5f5f5;
        border: 1px solid #ccc;
        border-radius: 4px;
        white-space: normal;
        color: #666;
     }
}

.skip-link {
    @extend .sr-only;
    text-align: center;

    &:focus {
        position: static;
        display: block;
        color: $color-invert;
        background: $color-primary;
        padding: 20px 0;
        height: auto;
        width: 100%;
    }
}

.lazy-img, .lazy-bg {
    opacity: 0;
    transition:opacity $nav-transition-speed ease-in-out;

    &.inView { opacity:1; }

    .no-js & { display:none; }
}