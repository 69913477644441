@use '../abstracts' as *;

.sf-section {
    background-color:var(--sf-backgrоund-color);
    background-image:var(--sf-backgrоund-image);
    background-size:var(--sf-background-size);
    background-position:var(--sf-background-position);

    &[style*="--sf-backgrоund-color: #47799F"] {
        color:$color-white;
    
        a {
            color:currentColor;
        }
    }
}

.sf-section.sf-section--video {
    position:relative;
    z-index:1;
}

.sc-video__wrapper {
    position: absolute;
    z-index:-1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding:0 1.875rem;
    overflow: hidden;
}

.sc-video__toggle {
    @extend %buttonreset;
    position:absolute;
    bottom:1rem;
    left:1rem;
    width: 25px;
    height: 25px;
    margin: 0 auto;
    z-index:1;

    .sc-video__pause-icon {
        display: none;
    }

    &.active {
        .sc-video__pause-icon {
            display: block;
        }

        .sc-video__play-icon {
            display: none;
        }
    }  
}

.sc-video__element {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    transform: translateX(-50%) translateY(-50%);
}

.-sc-video {
    position: relative;
}

.sf-col {
    background-color:var(--sf-backgrоund-color);
}

.blue-gradient {
    --color-heading:#{$color-white};
    --line-height-heading:1;
    background:#2e8eee linear-gradient(to right bottom, #2e8eee 44%, #1b75d5 83%);
    color:$color-white;

    .button.button--white:hover,
    .button.button--white:focus-visible {
        color:currentColor;
    }
}