@use '../abstracts' as *;

.site-footer {
    padding-bottom:15px;
}

.footer-top {
    display: flex;
    flex-direction: column;
    gap:15px;
    padding-block:14px;
    background:$color-white;
    text-align: center;
    background:$color-blue;
    color:$color-white;
    
    .footer-col + .footer-col {
        padding-top:15px;
        border-top:1px solid #4991dd;
    }

    a {
        color:currentColor;
    }
}

.footer-col.footer-col--address {
    font-style:italic;
    font-size:0.9375rem;
    
    p {
        margin: 5px 0 10px;
    }

    strong {
        font-size:clamp(1.2rem, 1.3vw, 1.125rem);
        font-weight:$fontWeight-semibold;
    }
}

.footer-bottom {
    display: flex;
    flex-direction: column;
    gap:15px;

    .footer-col {
        padding-inline:15px;

        &.footer-col--links {
            padding-inline:0;
        }
    }
}

.footer-col.footer-col--links {
    padding-block:15px;
    border-bottom: 1px solid #ddddde;

    a {
        font-size:0.875rem;
        color:#585858;
    }
}

.footer-col.footer-col--copyright {
    display: inline-flex;
    gap:5px;
    align-items: center;
    color: #979797;
    font-size: 0.75rem;
}

.footer-col.footer-col--builtby {
    color: #999;
    font-family: Tahoma;
    font-size: 0.70313em;
}

@media(min-width:$bp-mw){
    .footer-top {
        flex-direction: row;
        padding-inline:clamp(12px, 5.19vw, 70px);
        text-align: left;

        .footer-col + .footer-col {
            padding-top:0;
            padding-left:15px;
            border-top:0;
            border-left:1px solid #4991dd;
        }
    }

    .footer-bottom {
        flex-direction: row;
        flex-wrap:wrap;
        align-items: center;
        justify-content: center;
        padding-inline:clamp(12px, 5.19vw, 70px);

        .footer-col {
            padding-inline:0;
        }
    }

    .footer-col.footer-col--links {
        display: flex;
        flex-direction: column;
        align-items: center;
        width:100%;
        border-bottom: 1px solid #ddddde;
        padding-block:0;
        font-size:.875rem;

        ul {
            display: flex;
            align-items: center;
            justify-content: center;
            gap:30px;
            margin:0;
            padding:0 25px;
            list-style-type: none;
        }
    } 
}