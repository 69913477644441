@use '../abstracts' as *;
@use 'sass:math';

/* ================================================================
   Helper Classes
================================================================ */

$textAlignments:left, right, center;

$fontWeights: (
    "normal": $fontWeight-normal,
    "medium": $fontWeight-medium,
    "semibold": $fontWeight-semibold,
    "bold": $fontWeight-bold
);

$fonts: (
    "primary":$font-primary
);

$textCase:(
    "upper":"uppercase",
    "lower":"lowercase",
    "cap":"capitalized"
);

$displays:(
    "none": none,
    "block":block,
    "inline-block":inline-block,
    "inline":inline,
    "flex":flex,
    "inline-flex":inline-flex
);

$flexDirections:(
    "row":row,
    "row-reverse":row-reverse,
    "column":column,
    "column-reverse":column-reverse
);

$sides: (
    all:"",
    vertical:"y",
    horizontal:"x",
    top:"t", 
    right:"r", 
    bottom:"b", 
    left:"l"
);

$grids: (
    "2x2": 2,
    "3x3": 3
);

$spacer: 1rem;
$numOfSpacers:48;

$sectionPaddings: "small" 20px 20px, "medium" 40px 60px,
  "large" 40px 100px;

/* Mixins & Functions
================================================================ */
@mixin generate-text-alignment-classes($bpAbbv: '') {
    @each $alignment in $textAlignments {
        .text#{$bpAbbv}-#{$alignment} {
            text-align: $alignment;
        }
    }
}

@mixin generate-display-classes($bpAbbv: '') {
    @each $display, $displayVal in $displays {
        .d#{$bpAbbv}-#{$display} {
            display: $displayVal;
        }
    }
}

@mixin generate-flexDirection-classes($bpAbbv: '') {
    @each $direction, $directionVal in $flexDirections {
        .flex#{$bpAbbv}-#{$direction} {
            flex-direction: $directionVal;
        }
    }
}

@function spacer-value($multiplier) {
    @return $spacer * $multiplier;
}

@function strip-unit($number) {
    @if type-of($number) == 'number' and not unitless($number) {
      @return math.div($number, ($number * 0 + 1));
    }
  
    @return $number;
  }

@mixin generate-spacing-classes($bpAbbv: ''){
    @each $side, $sideValue in $sides {
        @for $i from 0 through $numOfSpacers {
            $spaceValue: spacer-value($i * 0.25);
        
            @if ($sideValue == "") {
                .p#{$sideValue}-#{$bpAbbv}#{$i} {
                    padding: $spaceValue;
                }
                .m#{$sideValue}-#{$bpAbbv}#{$i} {
                    margin: $spaceValue;
                }
            } @else if ($sideValue == "y") {
                .p#{$sideValue}-#{$bpAbbv}#{$i} {
                    padding-top: $spaceValue;
                    padding-bottom: $spaceValue;
                }
                .m#{$sideValue}-#{$bpAbbv}#{$i} {
                    margin-top: $spaceValue;
                    margin-bottom: $spaceValue;
                }
            } @else if ($sideValue == "x") {
                .p#{$sideValue}-#{$bpAbbv}#{$i} {
                    padding-left: $spaceValue;
                    padding-right: $spaceValue;
                }
                .m#{$sideValue}-#{$bpAbbv}#{$i} {
                    margin-left: $spaceValue;
                    margin-right: $spaceValue;
                }
            } @else {
                .p#{$sideValue}-#{$bpAbbv}#{$i} {
                    padding-#{$side}: $spaceValue;
                }
                .m#{$sideValue}-#{$bpAbbv}#{$i} {
                    margin-#{$side}: $spaceValue;
                }
            }

            .gap-#{$bpAbbv}#{$i} {
                gap: $spaceValue;
            }
        }

        @if ($sideValue == "") {
            .m#{$sideValue}-#{$bpAbbv}auto {
                margin: auto;
            }
        } @else if ($sideValue == "y") {
            .m#{$sideValue}-#{$bpAbbv}auto {
                margin-top: auto;
                margin-bottom: auto;
            }
        } @else if ($sideValue == "x") {
            .m#{$sideValue}-#{$bpAbbv}auto {
                margin-left: auto;
                margin-right: auto;
            }
        } @else {
            .m#{$sideValue}-#{$bpAbbv}auto {
                margin-#{$side}: auto;
            }
        }
    }
}

@mixin generate-section-spacing-classes(){
    @each $side, $sideValue in $sides {
        @each $name, $mobileVal, $desktopVal in $sectionPaddings {
            $vwValue: calc(strip-unit($desktopVal) / 1920) * 100;
            $spaceValue: clamp(#{$mobileVal}, #{$vwValue}vw, #{$desktopVal});

            @if ($sideValue == "") {
                .p#{$sideValue}-#{$name} {
                    padding: $spaceValue;
                }
                .m#{$sideValue}-#{$name} {
                    margin: $spaceValue;
                }
            } @else if ($sideValue == "y") {
                .p#{$sideValue}-#{$name} {
                    padding-top: $spaceValue;
                    padding-bottom: $spaceValue;
                }
                .m#{$sideValue}-#{$name} {
                    margin-top: $spaceValue;
                    margin-bottom: $spaceValue;
                }
            } @else if ($sideValue == "x") {
                .p#{$sideValue}-#{$name} {
                    padding-left: $spaceValue;
                    padding-right: $spaceValue;
                }
                .m#{$sideValue}-#{$name} {
                    margin-left: $spaceValue;
                    margin-right: $spaceValue;
                }
            }
            @else {
                .p#{$sideValue}-#{$name} {
                    padding-#{$side}: $spaceValue;
                }
    
                .m#{$sideValue}-#{$name} {
                    margin-#{$side}: $spaceValue;
                }
            }
        }
    }
}

@mixin generate-grid-classes($bpAbbv: ''){
    @each $grid, $value in $grids {
        .grid#{$bpAbbv}-#{$grid} {
            grid-template-columns: repeat($value, minmax(0px, 1fr));
        }
    
        .grid#{$bpAbbv}-#{$grid}-auto {
            grid-template-columns: repeat($value, minmax(auto, auto));
        }
    }
}

/* Text Helpers
================================================================ */
@each $fontWeight, $weightValue in $fontWeights {
    // .text-normal { font-weight:normal; }
    .text-#{$fontWeight} {
        font-weight:$weightValue;
    }
}

@each $alignment in $textAlignments {
    // .text-left { text-align:left; }
    .text-#{$alignment} {
        text-align:$alignment;
    }
}

@each $case, $caseValue in $textCase {
    // .text-upper { text-transform:uppercase; }
    .text-#{$case} {
        text-transform:$caseValue;
    }
}

@each $color, $colorValue in $colors {
    // .color-primary { color:$color-primary }
    .color-#{$color} {
        color:$colorValue;
    }
}

@each $font, $fontValue in $fonts {
    // .font-primary { font-family:$font-primary }
    .font-#{$font} {
        font-family:$fontValue;
    }
}

.text-italic {
    font-style: italic;
}

.fs-base {
    font-size:1rem;
}

.arrow-link {
    display: inline-flex;
    gap:5px;
    align-items: flex-start;    

    &.arrow-link--up {
        &:before {
            @include pseudo(block, static, '');
            border-bottom: 6px solid $color-blue;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            margin-top:9px;
        }
    }
}


/* Layout Helpers
================================================================ */
[class*="grid-"] {
    display: grid;
    gap:calc($grid-gutter-em * 2);

    &:is(ul) {
        @extend %listreset;
    }
}

/* Element Helpers
================================================================ */
@include generate-display-classes();
@include generate-grid-classes();
@include generate-flexDirection-classes();
@include generate-spacing-classes();
@include generate-section-spacing-classes();

.breakforprint { page-break-after:always; }

.full-width { width:100%; }
.list-unstyled, .unstyled-list { padding-left:0; list-style-type: none; }

.top-border {
    border-top:6px solid #e9e9e9;
}

.bottom-border {
    border-bottom:6px solid #e9e9e9;
}

.border-left-blue {
    border-left:11px solid $color-blue;
}

.w-100 {
    width:100%;
}
.w-75 {
    width:75%;
}
.w-50 {
    width:50%;
}
.w-25 {
    width:25%;
}


/* Media Queries
================================================================ */
@each $bpAbbv, $bp in $breakpoints {
    @media(min-width:$bp){
        @include generate-flexDirection-classes('-#{$bpAbbv}');
        @include generate-display-classes('-#{$bpAbbv}');
        @include generate-grid-classes('-#{$bpAbbv}');
        @include generate-text-alignment-classes('-#{$bpAbbv}');
        @include generate-spacing-classes('#{$bpAbbv}-');
    }
}