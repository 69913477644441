@use '../abstracts' as *;

/* ================================================================
   Generic Form Styles
   ================================================================ */

$input-height: 36px !default;
$input-background: #eeeeee !default;
$input-border-color: #d4d4d4 !default;
$input-border-width: 0 !default;
$input-border-radius: 0 !default;

.form-label {
    font-weight:$fontWeight-bold;
}

.form-control {
    width:100%;
    height:$input-height;
    border: $input-border-width solid $input-border-color;
    border-radius: $input-border-radius;
    padding: 5px 13px;
    background: $input-background;
    vertical-align: top;
    color: #656464;
    font-style: italic;
    font-size: 0.875rem;
    transition: all 0.3s ease-out;
    box-shadow:inset 0 0 0 rgba(0%, 0%, 0%, 0), inset 0 0 0px 0px transparent;

    &:focus {
        box-shadow:inset 0 5px 7px rgba(0, 0, 0, 0.22), inset 3px 3px 3px 0px #838383;
    }

    &::placeholder {
        font-style: italic;
    }
}

input[type="text"],
input[type="password"],
input[type="tel"],
input[type="email"] {
    @extend .form-control;
}

input[type="checkbox"],
input[type="radio"] {
    margin-right: 2px;
    border-radius: 0;
    background: none;
}

select {
    @extend .form-control;
    padding-right:0;
}

textarea {
    @extend .form-control;
    min-height:150px;
    
    &.form-control {
        padding-top:16px;
    }
}