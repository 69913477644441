/* ================================================================
   Flex Grid - Bootstrap Inspired
================================================================ */
@use '../abstracts' as *;

$grid-columns:12;
$colAlignments:flex-start,center,flex-end;

.container {
  width:100%; 
  max-width:$grid-container-width;
  margin-right: auto; 
  margin-left: auto;
  padding-left: $grid-gutter-em;
  padding-right: $grid-gutter-em;
}

.row { 
    display:flex;
    flex-wrap:wrap;
    row-gap:calc($grid-gutter-em * 2);

    & > * {
        position:relative;
        width:100%;
        max-width:100%;
        flex-shrink:0;
        padding-left:$grid-gutter-em;
        padding-right:$grid-gutter-em;
    }

    .sf-form & {
        margin-left:-$grid-gutter-em;
        margin-right:-$grid-gutter-em;
        row-gap: 0;
    }
}

.col {
    flex:1 0 0%;
    padding-left:$grid-gutter-em;
    padding-right:$grid-gutter-em;
}

.col-auto {
    flex:0 0 auto;
    width:auto;
}

.row-cols-max-content > * {
    flex-basis:max-content;
}

/* Mixins
================================================================ */
@mixin generate-order-and-cols($bpAbbv: '') {
    @for $i from 1 through $grid-columns {
        .order#{$bpAbbv}-#{$i} {
            order:$i;
        }
    
        .col#{$bpAbbv}-#{$i},
        .row-cols#{$bpAbbv}-#{$i} > * {
            flex: 0 0 auto;
            width:calc($i/$grid-columns * 100%);
        }
    }
}

@mixin generate-alignment-classes($bpAbbv:'') {
    @each $alignment in $colAlignments {
        .align-items#{$bpAbbv}-#{$alignment} {
            align-items:$alignment;
        }
    
        .justify-items#{$bpAbbv}-#{$alignment} {
            justify-content: $alignment;
        }
    }
}

/* Generate Grid
================================================================ */
@include generate-order-and-cols();
@include generate-alignment-classes();

@each $bpAbbv, $bp in $breakpoints {
    @media(min-width:$bp){
        @include generate-order-and-cols('-#{$bpAbbv}');
        @include generate-alignment-classes('-#{$bpAbbv}'); 
    }
}