@use '../../abstracts/' as *;

.interior-heading.interior-heading--has-content {
    position:relative;
    z-index: 1;
    overflow: hidden;
    padding:clamp(10px, 6.5vw, 90px);

    &:before {
        @include pseudo();
        top:0;
        left:0;
        z-index: 1;
        width:100%;
        height:100%;
        background:rgba(0,0,0, .6);
    }

    .interior-heading__img {
        position:absolute;
        top:0;
        left:0;
        min-width:100%;
        min-height:100%;
        max-width:none;
        object-fit: cover;
    }

    .interior-heading__content {
        position:relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
        gap:16px;
    }
    
    .interior-heading__title,
    .interior-heading__text {
        color:$color-white;
    }
}