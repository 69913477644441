@use '../../abstracts/' as *;

.accordion-list {
    display: flex;
    flex-direction: column;
    gap:10px;
}

.accordion-item__toggle {
    @extend %buttonreset;
}

.list-item {
    border-bottom:1px solid #e5e5e5;
}