@use '../../abstracts/' as *;

:root {
    --alert-bg:transparent;
    --alert-color:#{$color-base};
}

.alert {
    padding:1rem;
    background:var(--alert-bg);
    color:var(--alert-color);

    &.alert--blue {
        background:lighten($color-blue, 40%);
        color:$color-blue;
    }

    &.alert--yellow {
        --alert-bg:#fff3cd;
        --alert-color: #664d03;
    }

    &.alert--red {
        --alert-bg:#f8d7da;
        --alert-color: #721c24;
    }

    .alert__close {
        margin-left:auto;
    }
}

.alert__content {
    display:flex;
    align-items: center;
    gap:10px;
}

.alert__close {
    @extend %buttonreset;
    position: relative;
    width:20px;
    height:20px;
    border-radius: 50%;
    border:2px solid var(--alert-color);
    color:var(--alert-color);

    &:before,
    &:after {
        content:'';
        position:absolute;
        top:50%;
        left:50%;
        width:2px;
        height:50%;
        background:var(--alert-color);
    }

    &:before {
        transform:translate(-50%, -50%) rotate(45deg);
    }

    &:after {
        transform:translate(-50%, -50%) rotate(-45deg);
    }
}