@use '../../abstracts' as *;


.card {
    position:relative;
    border-radius:10px;
    padding:1.875rem 1.875rem 3rem 1.875rem;
    background:$color-white;
    box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.1);

    &:after {
        @include pseudo();
        bottom:0;
        left:0;
        width:100%;
        height:10px;
        background:$color-primary;
        border-radius: 0px 0px 10px 10px;
    }

    [class*="col-"] & {
        position:relative;
        height:100%;
    }
}

.card__body {
    display: flex;
    flex-direction: column;
    gap:20px;
}

.card__title {
    @extend %item__title;
    word-break:break-word;
}