@use '../abstracts' as *;
.site-header {
    position:relative;
    border-bottom:6px solid #e9e9e9;
}

.header-layout {
    display:flex;
    flex-wrap:wrap;
    justify-content: center;
    gap:10px;
    padding:10px 0;
}

@keyframes flo {
    0%,50%,100% {
        transform: translateY(0%);
      }

      25% {
        transform: translateY(20%);
      }

      75% {
        transform: translateY(-20%);
      }
}

.login-button {
    position:relative;
    display: inline-flex;
    align-items: center;
    gap:10px;
    padding: 10px 61px;
    background-color:$color-primary;
    background-image: linear-gradient(to bottom, #1a72cf 0%, #2063aa 100%);
    font-weight: $fontWeight-semibold;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    color:$color-white;

    .icon,
    svg {
        display: none;
        width:16px;
        height:16px;
    }

    &:hover,
    &:focus-visible {
        .icon,
        svg {
            animation: flo 0.5s infinite;
        }
    }
}

.header-col.header-col--nav {
    display: flex;
    justify-content: center;
    width:100%;
    padding:0 10px;
    border-bottom: 1px solid #e5e5e5;
    border-top: 1px solid #e5e5e5;
    
    .login-button {
        margin:0 auto;
    }

    .main-nav-toggle {
        margin-left:auto;
    }
}

.header-login {
    display: flex;
    flex-grow:1;
    align-items: center;
    justify-content: center;

    .login-status {
        margin:0 auto;
    }
}

.header-nav {
    position: fixed;
    top:0;
    right:-100%;
    display: none;
    width:33%;
    height:100%;
    background:$color-primary;
    color:$color-white;
    transition:right .3s ease-in-out, opacity .3s ease-in-out;

    .nav-open & {
        display: block;
    }

    .nav-animate & {
        right:0;
    }
}

.nav-open {
    position:relative;
    right:33%;
}

@media(min-width:$bp-mw){
    .header-layout {
        padding:0 clamp(10px, 6.5vw, 90px) 30px;
        flex-wrap: nowrap;

        .header-col.header-col--logo {
            flex-grow:1;
            padding:30px 0 0;
        }

        .header-col.header-col--nav {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            padding:0;

            .header-nav {
                margin-top:auto;
            }
        }
    }

    .header-col.header-col--nav {
        width:auto;
        border:0;
    }

    .header-nav {
        position:static;
        display: block !important;
        width:auto;
        height:auto;
        background:none;
        color:$color-base;
    }

    .header-login {
        flex-grow:0;
        align-items: flex-start;
        justify-content: flex-end;
        margin-top:-6px;
    }

    .login-button {
        padding-inline:48px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        .icon,
        svg {
            display: block;
        }
    }
}