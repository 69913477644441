@use '../../abstracts/' as *;

.hero {
    position:relative;
    z-index:1;
    overflow: hidden;
}

.hero__content {
    min-height: 140px;
    background: #bfbfbf;
    padding: 12px 15px;
    color:$color-white;
}

.hero__link {
    display: block;
    text-decoration: none;
    color:$color-white;

    &:hover, &:focus-visible {
        text-decoration: underline;
    }
}

.hero__title {
    margin: 0;
    font-size: clamp(1.9375rem, 3.07vw, 2.625rem);
    font-style: italic;
    line-height:1.4;
    color: $color-white;
}

.hero__action a {
    font-size: 0.875rem;
    font-weight: $fontWeight-semibold;
    text-transform: uppercase;
    text-decoration: none;
    color: $color-white;

    &:hover,
    &:focus-visible {
        text-decoration: underline;
    }
}

@media(min-width:$bp-m){
    .hero {
        height:490px;
    }

    .hero__img {
        position:absolute;
        top:0;
        left:0;
        z-index: -1;
        max-width:none;
        min-height:100%;
        object-fit: cover;
    }

    .hero__content {
        position:absolute;
        bottom:0;
        left:0;
        background: rgba(157, 157, 157, 0.7);
        width:100%;
        max-width: 460px;
        min-height: 0;
        padding: 15px 25px 15px 65px;
    }
}