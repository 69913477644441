@use '../abstracts' as *;

table {
	margin: 1em 0;
	border: 1px solid #ccc;
}

td {
	border: 1px solid #ccc;
	background: hsl(0, 0%, 90%);
	padding: 0.2em 0.4em;
}

tr:nth-child(2n) td {
	background: $color-white;
}