@use '../abstracts' as *;

.main-nav-toggle {
    @extend %buttonreset;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:3px;
    width:51px;
    padding:0 15px;

    &:before,
    & > span:not(.sr-only),
    &:after {
        content:"";
        display:block;
        width:21px;
        height:3px;
        border-radius: 2px;
        background:#656565;
    }

    & > span:not(.sr-only) {
        opacity:1;
        transition:opacity 0.3s ease-in-out;
    }

    &:before,
    &:after {
        transform:rotate(0);
        transition:transform 0.3s ease-in-out;
    }

    &.active {
        & > span:not(.sr-only) {
            opacity:0;
        }

        &:before {
            transform:rotate(45deg);
        }

        &:after {
            position:absolute;
            top:17px;
            transform:rotate(-45deg);
        }
    }
}

.main-nav-toggle__icon {
    width: 40px;
    height: 24px;
    display: inline-block;
    position: relative;
}

.main-nav-toggle__icon-inner {
    display: block;
    margin-top: cal(4px / -2);
    top: auto;
    bottom: 0;
    transition-duration: 0.13s;
    transition-delay: 0.13s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

    &,
    &::before,
    &::after {
        width: 40px;
        height: 4px;
        background-color: $color-white;
        border-radius: 4px;
        position: absolute;
        transition-property: transform;
        transition-duration: 0.15s;
        transition-timing-function: ease;
    }

    &::before,
    &::after {
        content: "";
        display: block;
    }

    &::before {
        top: 10px * -1;
        transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    &::after {
        bottom: 10px * -1;
        top: 20px * -1;
        transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
    }   
}

.main-nav__menu {
    display: flex;
    flex-direction: column;

    &:is(ul) {
        @extend %listreset;
    }
}

.main-nav__item {
    position:relative;

    &.main-nav__item--back {
        margin-top:6px;
    }
}

.main-nav__toggle {
    @extend %buttonreset;
    position: relative;
    display: flex;
    gap:.5rem;
    padding-right:1rem;
    font-family:$font-secondary;
    text-align: left;
    color:currentColor;

    &:after {
        @include pseudo();
        top:4px;
        right:0;
        width:7px;
        height:7px;
        border-bottom:2px solid currentColor;
        border-right:2px solid currentColor;
        transform:rotate(45deg);
    }
}

.main-nav__link-wrap {
    display: flex;
    gap:10px;

    .main-nav__link {
        padding:1rem 0 1rem 1rem;
        word-break: break-word;
    }

    .main-nav__sub-toggle {
        flex-shrink: 0;
        width:30px;
        margin-left:auto;
    }
}

.main-nav__link {
    display: block;
    padding:1rem;
    letter-spacing: -1px;
    color:currentColor;
    
    &:is(a) {
        text-decoration: none;
    }
}

.main-nav__sub {
    display: none;
    position: fixed;
    top:0;
    right:-100%;
    z-index:10;
    width:33%;
    height:100%;
    overflow-y: auto;
    background:$color-primary;

    &:is(ul) {
        @extend %listreset;
    }

    &.open {
        display: block;
        right:0;
    }

    .main-nav__link {
        font-size:0.9375rem;
    }
}

.main-nav__sub-toggle {
    @extend %buttonreset;
    position:relative;

    &:after {
        @include pseudo();
        top:50%;
        right:5px;
        border:6px solid transparent;
        border-left-color:$color-white;
        transform:translateY(-50%);
    }
}

.main-nav__back {
    @extend %buttonreset;

    &:before {
        @include pseudo();
        top:50%;
        left:5px;
        border:9px solid transparent;
        border-right-color:$color-white;
        transform:translateY(-50%);
    }
}

@media(min-width:$bp-mw){
    .main-nav-toggle,
    .main-nav__item.main-nav__item--back,
    .main-nav__sub-toggle {
        display: none;
    }

    .main-nav__menu {
        align-items: center;
        flex-direction: row;
        gap:clamp(20px, 2.19vw, 30px);
        margin-bottom:-30px !important;
        color:#525252;
    }

    .main-nav__item {
        &:hover,
        &:focus-within {
            & > .main-nav__sub {
                display:block;
            }
        }
    }

    .main-nav__link {
        padding:0;
    }

    .main-nav__link-wrap {
        display: block;
        
        .main-nav__link {
            padding:0;
        }
    }

    // Level 1
    .main-nav__menu > .main-nav__item {
        border-top:0;
        padding-top:0;
        padding-bottom:30px;

        & > .main-nav__sub {
            &:before {
                @include pseudo();
                top: -9px;
                border-bottom: 7px solid #1b75d5;
                border-left: 7px solid transparent;
                border-right: 7px solid transparent;
            }
        }
    }

    // Level 2
    .main-nav__sub {
        position:absolute;
        top:40px;
        left:3px;
        width:100%;
        min-width:11em;
        height:auto;
        border-top:2px solid $color-primary;
        border-radius: 5px;
        padding:2px !important;
        background:$color-white;
        box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.1);
        overflow: visible;
        color:$color-base;

        .main-nav__link {
            padding:14px 10px 14px 20px;
            font-size:0.875rem;
            transition:background .3s ease-in-out, color .3s ease-in-out;

            &:hover,
            &:focus-visible {
                background:$color-primary;
                color:$color-white;
            }
        }

        .main-nav__item .main-nav__sub {
            top:0;
            left:100%;
        }
    }
}