@use '../abstracts' as *;

h1, .h1, 
h2, .h2, 
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
	margin:0 0 1em;
	font-family:$font-heading;
	line-height: var(--line-height-heading);
	color:var(--color-heading);
}

h1, .h1 {
	font-weight:$fontWeight-bold;
}

h2, .h2, 
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
	font-weight:$fontWeight-normal;
}

h1, .h1 { font-size:var(--font-size-h1); }
h2, .h2 { font-size:var(--font-size-h2); }
h3, .h3 { font-size:var(--font-size-h3); }
h4, .h4 { font-size:var(--font-size-h4); }
h5, .h5 { font-size:var(--font-size-h5); }
h6, .h6 { font-size:var(--font-size-h6); }

h4, .h4,
h5, .h5 {
	text-transform: none;
}


a {
	color:$color-primary;
	text-decoration: underline;
	&:hover, &:focus { text-decoration:none; }
}

p {
    margin:0 0 $typography-margin-bottom;
	font-size:0.9375rem;
}

ul, ol {
	padding-left: 24px;
	margin: 0 0 $typography-margin-bottom;

	.site-main .sf-content-block &:not(.checkmark) li {
		margin:25px 0;
		font-size:.9375rem;
	}
}

ul.bullet {
	list-style-image:url('../images/layout/bullet.png');
}

ul.checkmark {
	display:flex;
	flex-direction: column;
	gap:clamp(5px, .8vw, 8px);
	padding-left:31px;
	list-style-image:url('../images/layout/checkmark.png');
	font-size:1.125rem;
	font-style: italic;
	color:$color-white;

	.site-main .sf-content-block & li {
		padding-left:20px;
		font-size:1.125rem;
	}
}

// Very simple blockquote styling
blockquote {
	margin:1em 1.5em; padding-left:1.5em;
	border-left:5px solid hsl(0, 0%, 85%);
}

.rail-title {
	margin: 0;
    padding-bottom: 10px;
    border-bottom: 1px solid #dadfe3;
    font-size: 1.4375rem;
    margin-bottom: 15px;
	color:$color-blue;

	a {
		text-decoration: none;
		
		&:hover,
		&:focus-visible {
			text-decoration: underline;
		}
	}
}