@use '../abstracts' as *;

%button {
    display: inline-block;
	border: 0;
	padding: 0.4em 1.5em;
	background: $color-primary;
    color: $color-white;
	text-decoration: none;
    transition:.2s ease-in-out;
    cursor: pointer;
    will-change: background;
    transition:background $nav-transition-speed ease-out;
}

.button {
	@extend %button;

	&:hover, &:focus-visible {
		text-decoration: none;
        background: darken($color-primary, 10%);
	}

	&.button--white {
		border-radius: 5px;
		padding: 14px 28px;
		box-shadow: 0 0 15px 40px $color-white inset;
		background:$color-white;
		font-style: italic;
		font-size:clamp(1.2rem, 1.6vw, 1.375rem);
		font-weight: $fontWeight-bold;
		text-transform: uppercase;
		text-shadow: 2px 2px transparent;
		color:$color-blue;
		transition:all .3s ease-in-out;

		&:hover,
		&:focus-visible {
			box-shadow: 0 0 1px 1px white inset;
			background: transparent;
		}
	}

	&.button--link {
		padding:0;
		background:none;
		text-decoration: underline;
		color:$color-blue;
		
		&:hover,
		&:focus-visible {
			text-decoration: none;
		}
	}
}