@use '../abstracts/' as *;

.rail-nav__menu {
    @extend %listreset;
    display: flex;
    flex-direction: column;
    gap:10px;

    li {
        margin:0;
    }
}

.rail-nav__link {
    display: inline-block;
    padding-left:15px;
    font-size: 0.875rem;
    text-decoration: none;
    color:#525252;
    transition:color .3s ease-in-out;

    &:hover, &:focus-visible {
        text-decoration:underline;
        color:$color-blue;
    }

    &.active {
        position: relative;
        display: inline-flex;
        gap:5px;
        font-style: italic;
        font-weight:$fontWeight-semibold;
        color:$color-blue;

        &:before {
            @include pseudo();
            top:5px;
            left:0;
            border-top: 5px solid transparent;
            border-left: 5px solid $color-blue;
            border-bottom: 5px solid transparent;
            flex-shrink: 0;
        }

        & + .rail-nav__sub {
            display:flex;
        }
    }
}

.rail-nav__sub {
    @extend %listreset;
    display:none;
    flex-direction: column;
    gap:10px;
    margin-top:10px;
    padding-left:10px;
    
    .rail-nav__link {
        padding-left:15px;
        font-size:0.8125rem;
    }
}